import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useDevices from '../../hooks/useDevices';
import Button from '../Button';
import { ReactComponent as ArrowLeft } from '../../static/svgs/arrow-left-black.svg';

const BACK_BUTTON_LABEL = 'Назад';

type Props = {
    onClick?: () => void;
    dataTestId?: string;
    label?: string;
    adaptive?: boolean;
};

const BackButton = ({ onClick, dataTestId = 'back-button', label = BACK_BUTTON_LABEL, adaptive }: Props) => {
    const navigate = useNavigate();
    const { isMobile } = useDevices();
    const backButtonClick = useCallback(() => {
        if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
    }, [onClick]);

    return (
        <Button
            onClick={backButtonClick}
            label={!isMobile ? label : undefined}
            adaptive={adaptive}
            type="outline"
            icon={<ArrowLeft />}
            dataTestId={dataTestId}
        />
    );
};

export default BackButton;
