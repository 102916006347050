import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import cn from 'classnames';
import type { SberidSDKSuccessResult } from '@sberid/js-sdk';

import type { IPromoCampaign } from '../../../api/types';
import { SBER_ID_REDIRECT_URL } from '../../../constants/sberId';
import { useAnalyticsQueue, useDevices, useGetSberIdClientInfo, useLoginClient } from '../../../hooks';
import { getNumbers } from '../../../utils/helper';
import { useAppSettings } from '../../../component/AppSettings';
import { useClient } from '../../../component/Client';
import type { ButtonSizes } from '../../../component/Button';
import SubmitButton from '../../../component/SubmitButton';
import SberIdButton from '../../../component/SberIdButton';
import Loader from '../../../component/Loader';
import ErrorContainer from '../../../component/ErrorContainer';
import type { FormValues, Submit } from './types';
import PhoneNumberField from './PhoneNumberField';
import PrivacyPolicyField from './PrivacyPolicyField';

import styles from './common.module.scss';

type Props = {
    title?: ReactNode;
    showAuthByPhoneNumber: boolean;
    showAuthBySberID: boolean;
    promoCampaign?: IPromoCampaign;
    phoneNumber?: string;
    onSberIdInstructionClick?: () => void;
};

enum PersonalizationStatus {
    LOADING,
    PERSONALIZED,
    NON_PERSONALIZED,
}

const ClientAuthForm = ({
    onSberIdInstructionClick,
    title,
    showAuthBySberID,
    showAuthByPhoneNumber,
    promoCampaign,
    phoneNumber = '',
}: Props) => {
    const methods = useForm<FormValues>({
        defaultValues: {
            phoneNumber,
            agreeWithConsent: false,
        },
        mode: 'onSubmit',
    });

    const { handleSubmit, getValues, trigger } = methods;

    const { mutateAsync: loginClient, error: loginClientError } = useLoginClient(
        'IDENTIFICATION_INSTALLATION_GET_CLICK'
    );
    const { client } = useClient();
    const { mutateAsync: getSberIdClientInfo, error: getSberIdClientInfoError } = useGetSberIdClientInfo();
    const { isMobile } = useDevices();
    const { consentId, smmrConsentId } = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();
    const [personalized, setPersonalized] = useState<PersonalizationStatus>(() =>
        showAuthBySberID ? PersonalizationStatus.LOADING : PersonalizationStatus.NON_PERSONALIZED
    );
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const isNonPersonalized = personalized === PersonalizationStatus.NON_PERSONALIZED;
    const isLoading = personalized === PersonalizationStatus.LOADING;
    const eventProps = { reauthorization: `${!!client}` };

    useMount(() => {
        pushMetrics('IDENTIFICATION_INSTALLATION_SHOW', eventProps);
    });

    const onSubmit: Submit = ({ phoneNumber }) => loginClient({ phoneNumber, consentId, smmrConsentId });

    const onSuccessCallback = async (data?: SberidSDKSuccessResult) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { state, code } = data!;

        const { phoneNumber } = await getSberIdClientInfo({
            state,
            code,
            redirectUri: SBER_ID_REDIRECT_URL,
        });

        await loginClient({ phoneNumber, consentId, smmrConsentId, state });
    };

    const onButtonClick = async () => {
        const result = await trigger();

        if (!result) {
            return null;
        }

        pushMetrics('IDENTIFICATION_SBERID_BTN_CLICK', eventProps);

        const phoneNumber = getValues('phoneNumber');

        return getNumbers(phoneNumber);
    };

    const onPersonalizationStatus = (personalization: boolean) => {
        setPersonalized(personalization ? PersonalizationStatus.PERSONALIZED : PersonalizationStatus.NON_PERSONALIZED);
    };

    const error = getSberIdClientInfoError || loginClientError;

    return (
        <FormProvider {...methods}>
            <form aria-label="form" onSubmit={handleSubmit(onSubmit)} className={isLoading ? styles.formHidden : ''}>
                {title}
                {error && (
                    <ErrorContainer
                        message={error?.message}
                        description={error?.description}
                        requestId={error?.requestId}
                    />
                )}
                {isNonPersonalized && (
                    <div className={styles.field}>
                        <PhoneNumberField />
                    </div>
                )}
                <div className={styles.field}>
                    <PrivacyPolicyField promoCampaign={promoCampaign} />
                </div>
                {showAuthByPhoneNumber && isNonPersonalized && (
                    <SubmitButton
                        label="Войти"
                        color="brand"
                        type={showAuthBySberID ? 'outline' : 'contained'}
                        size={buttonSize}
                        fullWidth
                    />
                )}
                {showAuthBySberID && (
                    <>
                        {showAuthByPhoneNumber && isNonPersonalized && (
                            <div className={styles.separator}>
                                <div className={cn(styles.line, styles.lineLeft)} />
                                <div className={styles.separatorText}>или</div>
                                <div className={cn(styles.line, styles.lineRight)} />
                            </div>
                        )}
                        <SberIdButton
                            onPersonalizationStatus={onPersonalizationStatus}
                            onButtonClick={onButtonClick}
                            onSuccessCallback={onSuccessCallback}
                        />
                        {onSberIdInstructionClick && isNonPersonalized && (
                            <div
                                className={styles.sberIdLink}
                                onClick={onSberIdInstructionClick}
                                data-test-id="sber-id-instruction-link"
                            >
                                Что значит «Войти по Сбер ID»
                            </div>
                        )}
                    </>
                )}
            </form>
            {isLoading && <Loader />}
        </FormProvider>
    );
};

export default ClientAuthForm;
